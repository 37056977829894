import React from 'react';
import { sortBy } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { addDays, isSameDay } from 'date-fns';
import { mapRoute } from '#/transit-tracker/utils/routes';
import { parseSignVanityName } from '#/shared/utils/transit';
import { TRAIN_ALL_CODES } from '#/shared/constants';
import { getNewTriMetDate } from '#/shared/utils/date-time';

const checkIsTrain = code => {
  return TRAIN_ALL_CODES.includes(code?.route?.route) ? code : false;
};

export const getArrivingVehicleType = arrival => {
  let vehicleType = '';
  switch (arrival) {
    case checkIsTrain(arrival):
      vehicleType = <FormattedMessage id="arrivals.train" />;
      break;
    default:
      vehicleType = <FormattedMessage id="arrivals.bus" />;
      break;
  }

  return vehicleType;
};

export const getArrivalScheduledLabel = scheduledTime => {
  const today = getNewTriMetDate();
  const tomorrow = addDays(today, 1);
  const scheduledDate = getNewTriMetDate(scheduledTime);

  if (isSameDay(scheduledDate, today)) {
    return <FormattedMessage id="arrivals.scheduled" />;
  }

  if (isSameDay(scheduledDate, tomorrow)) {
    return <FormattedMessage id="arrivals.tomorrow" />;
  }

  // if scheduled time is further than a day out, use the day of the week;
  // this expression translates the day value to the browser's selected language
  return scheduledDate.toLocaleDateString(window.navigator.language, {
    weekday: 'long',
  });
};

export const getStopFromArrival = arrival => ({
  id: arrival.stopId,
  lat: arrival.stopLat || arrival.lat,
  lon: arrival.stopLng || arrival.lon,
  isFavorite: arrival.isFavorite,
  stopName: arrival.stopName,
});

const sortArrivalsByRoute = arrivals =>
  sortBy(arrivals, [
    arrival => arrival.route.routeSortOrder,
    'vanityName',
    'stopName',
  ]);

export const removeDuplicateArrivals = arrivals => {
  const arrivalIds = new Set();
  return arrivals.filter(arrival => {
    const isItUnique = !arrivalIds.has(arrival.id);
    arrivalIds.add(arrival.id);
    return isItUnique;
  });
};

// TODO: The variables in this and other functions could use more descriptive names instead of 'a', for example
export const mapAndSortArrivals = (arrivals, stops, routes) =>
  sortArrivalsByRoute(
    arrivals.map(a => {
      // Check for id if locid is null due to some result sets giving us
      // different schema
      const stop = stops.find(
        s => String(s.locid || s.stopId || s.id) === String(a.locid)
      );

      const { feet, fullSign, id, showMilesAway, signRoute, status } = a;

      return {
        feet,
        fullSign,
        id,
        showMilesAway,
        signRoute,
        status,

        estimatedTime: a.estimated,
        scheduledTime: a.scheduled,
        route: mapRoute(
          routes.find(r => r.id.toString() === a.route.toString())
        ),
        vanityName: parseSignVanityName(a.fullSign, a.route),
        /* TODO: These fields currently have different names for the same info
        because FavoriteStops and NearbyStops currently have different schema.
        We should unify those, probably using the NearbyStops one */
        stopName: stop.desc || stop.stopName,
        stopId: stop.locid || stop.stopId || stop.id,
        stopFeetDistance: stop.feetDistance || stop.stopFeetDistance,
        dir: stop.dir,
        stopMetersDistance: stop.metersDistance || stop.stopMetersDistance,
        stopLat: stop.lat || stop.stopLat,
        stopLng: stop.lng || stop.stopLng,
        vehicleId: a.vehicleID || null,
        blockId: a.blockID || null,
      };
    })
  );

export const createSignKey = fullSign =>
  fullSign.toLowerCase().replace(/([^a-z0-9])/g, '_');

export const mapSignsForStop = (arrivals, routes, alerts) => {
  if (!arrivals?.length) {
    return null;
  }

  const signs = {};

  arrivals.forEach(a => {
    const key = createSignKey(a.fullSign);

    if (!signs[key]) {
      const { signRoute } = a;
      const route = mapRoute({
        ...routes.find(r => r.id === a.route.id),

        // this indicates the route number displayed on the head sign
        // which is occasionally different from the route number
        // associated with the arrival in the schedule data
        signRoute,
      });

      signs[key] = {
        route,
        arrivals: [],
        vanityName: parseSignVanityName(a.fullSign, route.id),
        alerts: alerts
          ? alerts.filter(alert => alert.route?.some(r => r.id === route.id))
          : [],
      };
    }
    signs[key].arrivals.push(a);
  });

  return signs;
};

export const mapVehicleStopsAndArrivals = (stops, arrivals) => {
  return stops.map(stop => {
    // TODO arrivals endpoint doesn't always return results for all requested locIDs, patching for now
    const arrival = arrivals.find(a => String(a.locid) === String(stop.locid));
    const minimalStop = {
      id: stop.locid.toString(),
      stopId: stop.locid,
      stopName: stop.desc,
      lat: stop.lat,
      lon: stop.lng,
    };
    if (arrival) {
      return {
        ...minimalStop,
        dir: arrival.dir,
        estimatedTime: arrival.estimated || null,
        scheduledTime: arrival.scheduled || null,
        feet: arrival.feet,
        showMilesAway: arrival.showMilesAway,
        status: arrival.status,
        dropOffOnly: arrival.dropOffOnly,
      };
    }
    return minimalStop;
  });
};

// Favorite Stops are really Arrivals
export const mapFaveStopArrivalToStop = stopArrival => ({
  locid: stopArrival.stopId,
  dir: stopArrival.dir,
  feetDistance: stopArrival.stopFeetDistance,
  metersDistance: stopArrival.stopMetersDistance,
  desc: stopArrival.stopName,
  lng: stopArrival.stopLng,
  lat: stopArrival.stopLat,
  isFavorite: true,
});

export const mapFaveStopArrivalsToStops = stops =>
  stops.map(stop => mapFaveStopArrivalToStop(stop));

/**
 * Process attributes of the input stop object to generate a return object that
 * aligns with `otp-ui`'s schema for a transit stop
 */
export const mapStopToOtp = stop => {
  const id = String(stop.id || stop.locid);

  return {
    ...stop,
    id,
    stopId: id,
    lon: stop.lng,
    name: stop.desc,
  };
};

export const getEtaOrScheduledTime = arrival => {
  if (arrival.estimatedTime != null) {
    return arrival.estimatedTime;
  }
  return arrival.scheduledTime;
};

// Given an array of arrivals, filter to arrivals for a favorited stop and route
export const filterToFavoriteArrivals = (arrivals, favoriteStops) => {
  if (!favoriteStops) {
    return [];
  }
  const favoriteStopRoutes = favoriteStops.map(
    fs => `${fs.stopId}${fs.route.id}`
  );
  const filteredArrivals = arrivals.filter(arrival =>
    favoriteStopRoutes.includes(`${arrival.stopId}${arrival.route.id}`)
  );
  const uniqueArrivals = removeDuplicateArrivals(filteredArrivals);
  return uniqueArrivals;
};

// Given an array of arrivals, filter to arrivals for a stop on the array passed in
export const filterToNearbyArrivals = (arrivals, nearbyStops) => {
  if (!nearbyStops) {
    return [];
  }

  const nearbyStopIds = nearbyStops.map(ns => ns.stopId || ns.locid);
  const filteredArrivals = arrivals.filter(arrival =>
    nearbyStopIds.includes(arrival.stopId)
  );
  const uniqueArrivals = removeDuplicateArrivals(filteredArrivals);
  return uniqueArrivals;
};

/** from a stop arrival record (e.g., has stop.signs[].arrivals[]), return the arrival record for given block */
export const findArrivalByBlock = (stop, block) => {
  let retVal = null;
  try {
    Object.values(stop.signs).forEach(sign => {
      const found = sign.arrivals.find(a => a.blockId.toString() === block);
      if (found) retVal = found;
    });
  } catch (e) {
    retVal = null;
  }
  return retVal;
};
