import { differenceInMinutes, parse } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

// date and time formats use Unicode Technical Standard #35 for compatibility
// with `date-fns`: https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table

const TRIMET_TIME_ZONE = 'America/Los_Angeles';
const MILLISECONDS_TIMESTAMP_FORMAT = 'T';

// formats required for inputs to OpenTripPlanner routing engine
const OTP_API_DATE_FORMAT = 'yyyy-MM-dd';
const OTP_API_TIME_FORMAT = 'HH:mm';

// this is an arbitrary character used to be able to parse a datetime
// string into date and time parts
const DATE_AND_TIME_SEPARATOR = '@';
const OTP_DATETIME_FORMAT =
  OTP_API_DATE_FORMAT + DATE_AND_TIME_SEPARATOR + OTP_API_TIME_FORMAT;

/**
 * Get the number of minutes that the supplied timestamp is from the current
 * time, a minutes floor is returned, meaning there is no rounding and any
 * seconds in excess of the last full minute are discarded. If the current time
 * is greater than the supplied time a negative value is returned
 *
 * @param milliSecondsTimeStamp Milliseconds since the epoch
 * @returns {Number} Minutes integer
 */
export const getMinutesFromNow = milliSecondsTimeStamp =>
  differenceInMinutes(new Date(milliSecondsTimeStamp), new Date());

/**
 * Format a Date object into OTP API date and time strings in the timezone
 * that the TriMet OTP engine is running.  OTP doesn't accept any information
 * pertaining to timezone for trip requests and assumes datetime information is
 * in its timezone
 *
 * @param {Date} date
 * @returns {String}
 */
export const formatToOtpApiDateAndTimeStrings = date =>
  formatInTimeZone(date, TRIMET_TIME_ZONE, OTP_DATETIME_FORMAT).split(
    DATE_AND_TIME_SEPARATOR
  );

export const parseMilliseconds = milliSeconds =>
  parse(milliSeconds, MILLISECONDS_TIMESTAMP_FORMAT, new Date());

/**
 * Convert text representation of date and time parts in the OTP API format to
 * a Date object. OTP engine query params don't contain timezone information and
 * the engine assumes all datetime info is in the timezone in which it's running
 *
 * @param {String} otpDateString
 * @param {String} otpTimeString
 * @returns {Date}
 */
export const parseFromOtpApiDateAndTimeStrings = (
  otpDateString,
  otpTimeString
) =>
  zonedTimeToUtc(
    parse(
      otpDateString + DATE_AND_TIME_SEPARATOR + otpTimeString,
      OTP_DATETIME_FORMAT,
      new Date()
    ),
    TRIMET_TIME_ZONE
  );

/** Converts supplied Date from the TriMet time zone to UTC  */
export const triMetTimeZoneToUtc = date =>
  zonedTimeToUtc(date, TRIMET_TIME_ZONE);

/** Converts supplied Date from UTC to the TriMet time zone  */
export const utcToTriMetTimeZone = date =>
  utcToZonedTime(date, TRIMET_TIME_ZONE);

/**
 * Get a new Date in the TriMet time zone.  If a time, in milliseconds since
 * the epoch, is not passed the current time will be used
 */
export const getNewTriMetDate = milliseconds =>
  utcToTriMetTimeZone(milliseconds ? new Date(milliseconds) : new Date());

export const userInTriMetTimeZone = () =>
  TRIMET_TIME_ZONE === Intl.DateTimeFormat().resolvedOptions().timeZone;
